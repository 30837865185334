import React from "react"
import {
  Button,
  setLocalStorage,
  IS_MEMBER_PROMO_DISPLAYED,
} from "@lesmills/gatsby-theme-common"
import type { OfferFieldGroupType } from "../../types/MemberPromoPopupType"

type Props = {|
  data: OfferFieldGroupType,
  handleRejectOffer: () => void,
  isProcessing: Boolean,
|}

const MemberPromoContent = ({
  data,
  handleRejectOffer,
  isProcessing,
}: Props) => {
  const handleRedeemOffer = () => {
    setLocalStorage(IS_MEMBER_PROMO_DISPLAYED, "true")
    window.location.href = `${process.env.GATSBY_MEMBER_PROMO_REDEMPTION_URL}?isFrom=cancellation&promoCode=${data?.promo_code}`
  }

  return (
    <div>
      <div className="border-b-4 border-green-accent">
        <picture>
          <source
            srcSet={data?.banner_image?.mobile?.url}
            media="(max-width: 599px)"
          />
          <source srcSet={data?.banner_image?.url} media="(min-width: 600px)" />
          <img
            alt="member-promo-banner"
            src={data?.banner_image?.url}
            className="h-200 w-100/100 object-cover"
          />
        </picture>
      </div>
      <div className="py-24 px-16">
        <div className="flex flex-col">
          {data?.offer_label && (
            <div className="w-max-content bg-green-accent rounded text-3xs tracking-0.08 py-1 px-8 font-avenir-roman">
              {data?.offer_label}
            </div>
          )}
          {data?.offer_title && (
            <p className="text-3xl font-base-heavy mt-16">
              {data?.offer_title}
            </p>
          )}
          {data?.offer_description && (
            <p className="text-base font-avenir-medium leading-snug mt-16">
              {data?.offer_description}
            </p>
          )}
          {data?.offer_term_and_conditions && (
            <p className="text-base font-avenir-medium leading-snug mt-16">
              {data?.offer_term_and_conditions}
            </p>
          )}
        </div>

        <div className="mt-32 flex flex-col">
          <Button
            className={`h-48 text-3xs font-avenir-roman block text-gray-800 border border-gray-800 rounded-full`}
            handleOnClick={handleRedeemOffer}
            testId="remeem-offer"
            disabled={isProcessing}
          >
            {data?.redeem_offer_button_label}
          </Button>
          <Button
            className={`h-48 text-3xs font-avenir-roman block text-gray-800 border border-gray-800 mt-12 mb-20 rounded-full relative`}
            handleOnClick={handleRejectOffer}
            testId="cancel-subscription"
            disabled={isProcessing}
          >
            {isProcessing ? "PROCESSING..." : data?.reject_offer_button_label}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MemberPromoContent
